import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';

import Home from './js/router/home';
import FinalForm from './js/router/finalForm';
import Header from './js/router/header';
import {VirtualPageview} from './js/components/googleAnalytics';

class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <React.Fragment>
                    <Route path="*" component={Header}/>
                    <Route path="*" component={VirtualPageview}/>
                    <div className="app container-fluid">
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/final-form" component={FinalForm}/>
                    </div>
                </React.Fragment>
            </BrowserRouter>
        );
    }
}

export default App;
