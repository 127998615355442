import React from 'react';
import _ from 'lodash';
import {Field, Form} from 'react-final-form';
import {Alert} from 'reactstrap';

import * as GA from './../components/googleAnalytics';

export default class FinalForm extends React.Component {
    onSubmit = (form, formApi) => {
        let {reset} = formApi;
        console.log('submitting');
        GA.sendEvent('final-form-submit', 'forms', form.something);
        reset();
    };

    validate = (form) => {
        let errors = {};
        if(_.isEmpty(_.trim(form.something))) {
            errors.something = 'Required';
        }
        return errors;
    };

    render() {
        return (
            <div className="final-form">
                <Form onSubmit={this.onSubmit}
                      validate={this.validate}
                      render={({handleSubmit, submitError}) => (
                          <form onSubmit={handleSubmit}>
                              <h2>Welcome to the Final Form</h2>
                              <div className="form-group">
                                  <Field name="something">
                                  {({ input, meta }) => (
                                      <React.Fragment>
                                          <label className={meta.error ? 'error' : undefined}>Type Something</label>
                                          <input {...input} className="form-control" type="text" placeholder="Something here..." />
                                          {meta.error && meta.touched &&
                                            <Alert color="danger" className="mt-2">{meta.error}</Alert>
                                          }
                                      </React.Fragment>
                                  )}
                                  </Field>
                              </div>
                              {submitError && <div className="something-error">Error: {submitError}</div>}
                              <button className="btn btn-lg btn-primary">Submit</button>
                          </form>
                      )}>
                </Form>
            </div>
        );
    }
}