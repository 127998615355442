/* eslint-disable */
import React from 'react';

const GA_TRACKING_ID = 'UA-128876903-1';

export class VirtualPageview extends React.Component {
    componentDidUpdate(prevProps) {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            console.log('pushing page view', this.props.location.pathname);
            gtag('config', GA_TRACKING_ID, {page_path: `${this.props.location.pathname}`});
        }
    }

    render() {
        return null;
    }
}

export function sendEvent(action, category, label, value) {
    gtag('event', action, {event_category: category, event_label: label, value});
}