import React from 'react';

export default class Home extends React.Component {
    render() {
        return (
            <div className="home">
                <h1>Andrew Chepey</h1>
            </div>
        );
    }
}