import React from 'react';
import Logo from './../../img/chepey-logo.png'
import {Link} from 'react-router-dom';

export default class Header extends React.Component {
    render() {
        return (
            <header className="header">
                <div className="logo">
                    <Link to="/">
                        <img src={Logo} alt=""/>
                    </Link>
                </div>
                <div className="menu">
                    <Link to="final-form">click me</Link>
                </div>
            </header>
        );
    }
}